import { App } from './types';
import axiosInstance from '../../lib/api/axios';
import { BFF_URL } from '../../lib/config';

export const getApps = async (userId: number): Promise<App[]> => {
  const { data } = await axiosInstance.get<App[]>(`${BFF_URL}/apps/?userId=${userId}`);
  return data;
};

export const acceptInvite = async (appId: number, userId: number): Promise<void> => {
  await axiosInstance.post(`${BFF_URL}/apps/${appId}/actions/acceptInvite?userId=${userId}`);
};
