import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { useNotification } from 'spindl-shared-components';

import { NOTIFICATION_DURATION } from '../../../lib/constants';
import { getTranslation } from '../../../lib/reactUtils';
import { AppsContext } from '../../../modules/apps/AppsContext';
import { MembersContext } from '../../../modules/member/MembersContext';
import { deleteInviteUseCase } from './DeleteInviteModal.interactor';
import { DeleteInviteModalCombinedProps } from './types';

const usePresenter = (props: DeleteInviteModalCombinedProps): DeleteInviteModalCombinedProps => {
  const { t } = useTranslation();
  const { trigger } = useNotification();

  const { activeApp } = useContext(AppsContext);
  const { selectedInvite, updateModalState, refetchOpenInvites } = useContext(MembersContext);

  const getDeleteInviteMessage = () => {
    return getTranslation('delete_invite_modal.message', {
      email: selectedInvite?.email,
      app: activeApp?.name,
    });
  };

  const deleteInviteMutation = useMutation(deleteInviteUseCase);

  const handleDeleteInvite = () => {
    if (!selectedInvite) {
      return;
    }

    const onSuccess = () => {
      updateModalState('UNSET');
      trigger({
        message: getTranslation('toast_notification.delete_invite_message'),
        type: 'Positive',
        duration: NOTIFICATION_DURATION,
      });

      if (refetchOpenInvites) {
        refetchOpenInvites();
      }
    };

    deleteInviteMutation.mutate(selectedInvite?.id, {
      onSuccess,
    });

  };

  return {
    ...props,
    closeButton: {
      icon: {
        asset: 'Close',
      },
      onClick: () => updateModalState('UNSET'),
    },
    title: {
      value: t('delete_invite_modal.delete_invite'),
    },
    message: {
      value: getDeleteInviteMessage(),
    },
    teamCard: {
      email: {
        value: selectedInvite?.email,
      },
      status: {
        value: t('team_card.pending'),
      },
    },
    deleteButton: {
      text: {
        value: t('delete_invite_modal.delete_invite'),
      },
      onClick: handleDeleteInvite,
    },
    cancelButton: { 
      text: {
        value: t('delete_invite_modal.cancel_button'),
      },
      onClick: () => updateModalState('UNSET'),
    },
  };
};

export default usePresenter;
