import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { NotificationProvider, AuthProvider } from 'spindl-shared-components';

import { LocaleProvider } from './modules/locale';
import { AppsProvider } from './modules/apps/AppsContext';
import { MembersProvider } from './modules/member/MembersContext';
import { UserProvider } from './modules/user';

const queryClient = new QueryClient();

const AppProvider: React.FC<{}> = ({ children }) => (
  <LocaleProvider>
    <NotificationProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <UserProvider>
            <AppsProvider>
              <MembersProvider>
                {children}
              </MembersProvider>
            </AppsProvider>
          </UserProvider>
        </AuthProvider>
      </QueryClientProvider>
    </NotificationProvider>
  </LocaleProvider>
);

export default AppProvider;
