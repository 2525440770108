import Cookie from 'js-cookie';
import { DOMAIN, SESSION_COOKIE_NAME } from '../../lib/config';

export const logout = (): void => {
  Cookie.remove(SESSION_COOKIE_NAME as string, {
    domain: DOMAIN,
    secure: true,
  });
  Cookie.remove(`${SESSION_COOKIE_NAME}-legacy`, {
    domain: DOMAIN,
    secure: true,
    path: '/',
  });
};
