import React from 'react';
import cx from 'classnames';
import { Route, Switch } from 'react-router-dom';

import ForgotPasswordBlock from '../../blocks/ForgotPasswordBlock';
import InformationBlock from '../../blocks/InformationBlock';
import ResetPasswordBlock from '../../blocks/ResetPasswordBlock';
import SignInBlock from '../../blocks/SignInBlock';
import SignUpBlock from '../../blocks/SignUpBlock';
import ErrorPage from '../ErrorPage';

import styles from './SignInPage.module.scss';
import usePresenter from './SignInPage.presenter';
import getProps from './SignInPage.props';
import { SignInPageCombinedProps } from './types';
import { AUTH_ROUTES } from '../../../lib/constants';

const SignInPage: React.FC<SignInPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    signInBlock,
    confirmationForgotPasswordBlock, // MANUAL OVERRIDE
    confirmationUpdatePasswordBlock, // MANUAL OVERRIDE
    forgotPasswordBlock, // MANUAL OVERRIDE
    invalidInviteBlock, // MANUAL OVERRIDE
    resetPasswordBlock, // MANUAL OVERRIDE
    signUpBlock, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.signInPage;

  return (
    <div className={cx(currentStyle, className)}>
      {/* MANUAL OVERRIDE */}
      <Switch>
        <Route path={AUTH_ROUTES.forgotPasswordConfirmed}>
          <InformationBlock
            className={cx(styles.confirmationForgotPasswordBlock, classes?.confirmationForgotPasswordBlock)}
            {...internalProps.confirmationForgotPasswordBlock}
            {...confirmationForgotPasswordBlock}
          />
        </Route>
        <Route path={AUTH_ROUTES.updatedPasswordConfirmed}>
          <InformationBlock
            className={cx(styles.confirmationUpdatePasswordBlock, classes?.confirmationUpdatePasswordBlock)}
            {...internalProps.confirmationUpdatePasswordBlock}
            {...confirmationUpdatePasswordBlock}
          />
        </Route>
        <Route path={AUTH_ROUTES.forgotPassword}>
          <ForgotPasswordBlock
            className={cx(styles.forgotPasswordBlock, classes?.forgotPasswordBlock)}
            {...internalProps.forgotPasswordBlock}
            {...forgotPasswordBlock}
          />
        </Route>
        <Route path={AUTH_ROUTES.updatePassword}>
          <ResetPasswordBlock
            className={cx(styles.signUpBlock, classes?.resetPasswordBlock)}
            {...internalProps.resetPasswordBlock}
            {...resetPasswordBlock}
          />
        </Route>
        <Route path={AUTH_ROUTES.signUp}>
          <SignUpBlock
            className={cx(styles.signUpBlock, classes?.signUpBlock)}
            {...internalProps.signUpBlock}
            {...signUpBlock}
          />
        </Route>
        <Route path={AUTH_ROUTES.invalidInvite}>
          <InformationBlock
            className={cx(styles.invalidInviteBlock, classes?.invalidInviteBlock)}
            {...internalProps.invalidInviteBlock}
            {...invalidInviteBlock}
          />
        </Route>
        <Route path={AUTH_ROUTES.signIn}>
          <SignInBlock
            className={cx(styles.signInBlock, classes?.signInBlock)}
            {...internalProps.signInBlock}
            {...signInBlock}
          />
        </Route>
        <Route>
          <ErrorPage />
        </Route>
      </Switch>
    </div >
  );
};

export default SignInPage;
