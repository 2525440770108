import cx from 'classnames';
import { TeamCardCombinedProps } from './types';
import customStyles from './TeamCardOverride.module.scss';

const usePresenter = (props: TeamCardCombinedProps): TeamCardCombinedProps => {
  return {
    ...props,
    className: cx(props.className, customStyles.teamCard),
    classes: {
      buttonContainer: customStyles.buttonContainer,
      content: customStyles.content,
      header: customStyles.header,
      spacerContainer: customStyles.spacerContainer,
      email: customStyles.email,
      titleWithDescription: customStyles.titleWithDescription,
    },
  };
};

export default usePresenter;
