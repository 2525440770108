import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AUTH_ROUTES } from '../constants';
import { logout } from '../../modules/auth/utils';

const instance = axios.create({ withCredentials: true });

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => config;

const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const onResponse = (response: AxiosResponse): AxiosResponse => response;

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  // log out unauthorized user
  if (error.response?.status === 401 && window.location.pathname !== AUTH_ROUTES.signIn) {
    logout();
    const pathname = window.location.pathname;
    window.location.href = `${AUTH_ROUTES.signIn}?return_to=${encodeURIComponent(pathname)}`;
  }

  return Promise.reject(error);
};

instance.interceptors.request.use(onRequest, onRequestError);
instance.interceptors.response.use(onResponse, onResponseError);

export default instance;
