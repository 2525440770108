import { VerticalTabItemProps } from '../VerticalTabItem';

type VerticalTabListInternalProps = {
  verticalTabItem?: VerticalTabItemProps;
};

type VerticalTabListPropsValues = {
  '': VerticalTabListInternalProps;
};

const propValues: VerticalTabListPropsValues = {
  '': {
    verticalTabItem: {
      state: 'Unselected',
    },
  },
};

const getProps = (type: string): VerticalTabListInternalProps => {
  const values: VerticalTabListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
