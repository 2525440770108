/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import cx from 'classnames';

import {
  Icon,
} from 'spindl-shared-components';

import styles from './VerticalTabItem.module.scss';
import usePresenter from './VerticalTabItem.presenter';
import getProps from './VerticalTabItem.props';
import { VerticalTabItemCombinedProps } from './types';

const VerticalTabItem: React.FC<VerticalTabItemCombinedProps> = (props) => {
  const {
    state,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    icon,
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`verticalTabItem${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Selected':
    case 'Unselected': {
      componentView = (
        <button
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}
        >
          <div className={cx(styles.content, classes?.content)}>
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}
            />
          </div>
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default VerticalTabItem;
