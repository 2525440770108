import React, { createContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from 'spindl-shared-components';
import { getMyUserUseCase } from '../../components/blocks/SignInBlock/SignInBlock.interactor';
import { User } from './types';

export type UserContextValue = {
  user?: User;
};

const initialUserContext: UserContextValue = {
  user: undefined,
};

export const UserContext = createContext<UserContextValue>(initialUserContext);

export const UserProvider: React.FC<{}> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const { account } = useAuth();

  const { data, isSuccess } =  useQuery('users/me', getMyUserUseCase, { enabled: !!account });
  useEffect(() => {
    if (account && isSuccess) {
      setUser(data);
    }
  }, [account, data, isSuccess]);

  return (
    <UserContext.Provider value={{
      user,
    }}>
      {children}
    </UserContext.Provider>
  );
};
