import { useHistory, useParams } from 'react-router-dom';
import cx from 'classnames';
import { LeftNavCombinedProps } from './types';
import customStyles from './LeftNavOverride.module.scss';
import { SPINDL_SERVICES } from '../../../lib/constants';

const usePresenter = (props: LeftNavCombinedProps): LeftNavCombinedProps => {
  const history = useHistory();
  const { activePage, appId } = useParams<{ appId: string, activePage: string }>();
  const currService = activePage === 'users' ? 'identity' : activePage;

  const changeActivePage = (page: string) => {
    if (page === 'identity') {
      history.push(`/apps/${appId}/users`);
    } else {
      history.push(`/apps/${appId}/${page}`);
    }
  };

  return {
    ...props,
    classes: {
      ...props.classes,
      serviceMenu: cx(props.classes?.serviceMenu, customStyles.serviceMenu),
    },
    serviceList: {
      verticalTabItems: SPINDL_SERVICES?.map((service) => {
        return {
          icon: {
            asset: service.icon,
          },
          state: service.name === currService ? 'Selected' : 'Unselected',
          onClick: () => changeActivePage(service.name as string),
        };
      }),
    },
    settingsList: {
      verticalTabItems: [
        {
          icon: {
            asset: 'TeamHollow',
          },
          state: activePage === 'team' ? 'Selected' : 'Unselected',
          onClick: () => changeActivePage('team'),
        },
      ],
    },
  };
};

export default usePresenter;
