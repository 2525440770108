import React from 'react';
import cx from 'classnames';

import VerticalTabItem from '../VerticalTabItem';

import styles from './VerticalTabList.module.scss';
import usePresenter from './VerticalTabList.presenter';
import getProps from './VerticalTabList.props';
import { VerticalTabListCombinedProps } from './types';

const VerticalTabList: React.FC<VerticalTabListCombinedProps> = (props) => {
  const {
    className,
    classes,
    verticalTabItems,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.verticalTabList;

  const verticalTabItemViews = verticalTabItems?.map((verticalTabItem, index) => (
    <VerticalTabItem
      key={index}
      className={cx(styles.verticalTabItem, classes?.verticalTabItem)}
      {...internalProps.verticalTabItem}
      {...verticalTabItem}
    />
  )) || [];

  return (
    <div className={cx(currentStyle, className)}>
      {verticalTabItemViews}
    </div>
  );
};

export default VerticalTabList;
