import { TeamsBlockProps } from '../../blocks/TeamsBlock';
import { PageHeaderProps } from '../../organisms/PageHeader';


type TeamsPageInternalProps = {
  pageHeader?: PageHeaderProps;
  teamsBlock?: TeamsBlockProps;
};

type TeamsPagePropsValues = {
  '': TeamsPageInternalProps;
};

const propValues: TeamsPagePropsValues = {
  '': {
    pageHeader: {
    },
    teamsBlock: {
    },
  },
};

const getProps = (type: string): TeamsPageInternalProps => {
  const values: TeamsPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
