import React, { useContext, useEffect } from 'react';
import { Redirect, RouteProps, useHistory } from 'react-router-dom';
import { useAuth } from 'spindl-shared-components';
import { AUTH_ROUTES } from '../../lib/constants';
import { AppsContext } from './AppsContext';

const DefaultAppRoute: React.FC<RouteProps> = () => {
  const { account } = useAuth();
  const { apps, isLoading, isError } = useContext(AppsContext);
  const history = useHistory();
  const path = history.location.pathname;

  useEffect(() => {
    if (!isLoading && apps?.length) {
      history.push(`/apps/${apps[0].id}/design`);
    }
  }, [isLoading, apps, history, path]);

  if (!account) {
    return <Redirect to={AUTH_ROUTES.signIn} />;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>An error ocurred when fetching the user's apps.</div>;
  }

  if (apps && !apps.length) {
    return <div>You are not a member of any apps yet.</div>;
  }

  return null;
};

export default DefaultAppRoute;
