import { ExportedModalProps, ModalWrapper } from 'spindl-shared-components';
import InternalTeammateInviteModal from './TeammateInviteModal';
import {
  TeammateInviteModalProps, 
  TeammateInviteModalValueProps as InternalTeammateInviteModalValueProps,
} from './types';

export type { TeammateInviteModalProps }; // MANUAL OVERRIDE

// MANUAL OVERRIDE
export type TeammateInviteModalValueProps = ExportedModalProps<InternalTeammateInviteModalValueProps>;

// MANUAL OVERRIDE
const TeammateInviteModal: React.FC<TeammateInviteModalValueProps> = ({ className, onHide, show, modalProps }) => (
  <ModalWrapper
    className={className}
    content={<InternalTeammateInviteModal {...modalProps} />}
    onHide={onHide}
    show={show}
  />
);

export default TeammateInviteModal;
