import {
  TextProps,
} from 'spindl-shared-components';

type InitialsInternalProps = {
  text?: TextProps;
};

type InitialsPropsValues = {
  'Large': InitialsInternalProps;
  'Small': InitialsInternalProps;
};

const propValues: InitialsPropsValues = {
  'Large': {
    text: {
      align: 'Center',
      styleType: 'SemiBold',
      colour: 'Subdued',
      type: 'Heading1',
    },
  },
  'Small': {
    text: {
      align: 'Center',
      styleType: 'SemiBold',
      colour: 'Subdued',
      type: 'Heading6',
    },
  },
};

const getProps = (type: string): InitialsInternalProps => {
  const values: InitialsInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
