import { ExportedModalProps, ModalWrapper } from 'spindl-shared-components';
import InternalDeleteTeammateModal from './DeleteTeammateModal';
import {
  DeleteTeammateModalProps,
  DeleteTeammateModalValueProps as InternalDeleteTeammateModalValueProps,
} from './types';

export type { DeleteTeammateModalProps }; // MANUAL OVERRIDE

// MANUAL OVERRIDE
export type DeleteTeammateModalValueProps = ExportedModalProps<InternalDeleteTeammateModalValueProps>;

// MANUAL OVERRIDE
const DeleteTeammateModal: React.FC<DeleteTeammateModalValueProps> = ({ className, onHide, show, modalProps }) => (
  <ModalWrapper
    className={className}
    content={<InternalDeleteTeammateModal {...modalProps} />}
    onHide={onHide}
    show={show}
  />
);

export default DeleteTeammateModal;
