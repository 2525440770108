import React from 'react';
import { DropdownWrapper, ExportedDropdownProps, DropDownMenuValueProps, DropDownMenu } from 'spindl-shared-components';

import InternalAppDropDown from './AppDropDown';
import { AppDropDownProps, AppDropDownValueProps as InternalAppDropDownValueProps } from './types';

export type { AppDropDownProps }; // MANUAL OVERRIDE

// MANUAL OVERRIDE
export type AppDropDownValueProps = ExportedDropdownProps<InternalAppDropDownValueProps, DropDownMenuValueProps>;

// MANUAL OVERRIDE
const AppDropDown: React.FC<AppDropDownValueProps> = ({ id, toggleProps, menuProps, className }) => (
  <DropdownWrapper
    id={id}
    Toggle={InternalAppDropDown}
    Menu={DropDownMenu}
    toggleProps={toggleProps}
    menuProps={menuProps}
    className={className}
  />
);

export default AppDropDown;
