import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppsContext } from '../../../modules/apps/AppsContext';
import { AppDropDownValueProps } from '.';

export const useAppDropdown = (): AppDropDownValueProps => {
  const history = useHistory();
  const { activePage } = useParams<{ appId: string, activePage: string }>();
  const { apps, activeApp } = useContext(AppsContext);

  const changeActiveApp = (id?: number) => {
    if (id !== activeApp?.id) {
      history.push(`/apps/${id}/${activePage}`);
    }
  };

  return {
    menuProps: {
      dropDownList: {
        dropDownItems: apps?.map((app, index) => {
          return {
            type: 'DefaultText',
            text: {
              value: app.name,
              dataTestId: `app-name${index}-text`,
            },
            onClick: () => changeActiveApp(app.id),
            state: app === activeApp ? 'Selected' : 'Unselected',
          };
        }),
      },
    },
    toggleProps: {
      appName: {
        value: activeApp?.name,
        dataTestId: 'active-app-name-text',
      },
      appImage: {
        imageSrc: activeApp?.iconUrl,
      },
    },
  };
};
