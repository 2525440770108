import React from 'react';
import { Trans } from 'react-i18next';
import i18n from '../modules/locale/i18n';

export const getTranslation = (transKey: string, values?: Record<string, unknown>): React.ReactNode => {
  return (
    <Trans
      i18n={i18n}
      i18nKey={transKey}
      values={values}
      components={{
        bold: <strong />,
      }}
    />
  );
};

export const replaceNewlines = (text: string): React.ReactNode[] => {
  const NEW_LINE_REGEX = /\r\n|\r|\n/g;
  return text.split(NEW_LINE_REGEX).flatMap((e) => [<br />, e]).slice(1);
};
