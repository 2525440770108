import {
  TextProps,
  SpindlLogoProps,
} from 'spindl-shared-components';

type LogoWithHeaderInternalProps = {
  spindlLogo?: SpindlLogoProps;
  text?: TextProps;
  lowerText?: TextProps;
};

type LogoWithHeaderPropsValues = {
  'Title': LogoWithHeaderInternalProps;
  'TitleWithDescription': LogoWithHeaderInternalProps;
};

const propValues: LogoWithHeaderPropsValues = {
  'Title': {
    spindlLogo: {
    },
    text: {
      align: 'Center',
      styleType: 'SemiBold',
      colour: 'Default',
      type: 'Heading3',
    },
  },
  'TitleWithDescription': {
    spindlLogo: {
    },
    text: {
      align: 'Center',
      styleType: 'SemiBold',
      colour: 'Default',
      type: 'Heading3',
    },
    lowerText: {
      align: 'Center',
      styleType: 'Regular',
      colour: 'Subdued',
      type: 'Body1',
    },
  },
};

const getProps = (type: string): LogoWithHeaderInternalProps => {
  const values: LogoWithHeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
