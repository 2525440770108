import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { DesignTopNav } from 'spindl-studio-mod';
import cx from 'classnames';

import { BASE_SERVICE_ROUTES } from '../../../lib/constants';
import AppDropDown from '../../molecules/AppDropDown';
import { useAppDropdown } from '../../molecules/AppDropDown/useAppDropdown';
import { MainLayoutCombinedProps } from './types';
import customStyles from './MainLayoutOverride.module.scss';

const usePresenter = (props: MainLayoutCombinedProps): MainLayoutCombinedProps => {
  const { pathname } = useLocation();
  const appDropDownProps = useAppDropdown();
  const [isDesignModule, setIsDesignModule] = useState<boolean>();

  let customTopNavComponent: MainLayoutCombinedProps['CustomTopNav'];
  let customTopNavProps: MainLayoutCombinedProps['customTopNav'];

  useEffect(() => {
    setIsDesignModule(!!matchPath(pathname, BASE_SERVICE_ROUTES.design));
  }, [pathname]);

  if (isDesignModule) {
    customTopNavProps = {
      renderSpindlAppDropdown: () => (
        <AppDropDown {...appDropDownProps} />
      ),
    };
    customTopNavComponent = DesignTopNav;
  }

  return {
    ...props,
    className: cx(props.className, customStyles.mainLayout),
    classes: {
      content: customStyles.content,
      teamsPage: customStyles.teamsPage,
      leftNav: customStyles.leftNav,
    },
    customTopNav: customTopNavProps,
    CustomTopNav: customTopNavComponent,
  };
};

export default usePresenter;
