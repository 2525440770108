import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'spindl-shared-components';

import { AUTH_ROUTES } from '../../../lib/constants';
import { ErrorPageCombinedProps } from './types';

const usePresenter = (props: ErrorPageCombinedProps): ErrorPageCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { account } = useAuth();
  const activeAppId = localStorage.getItem('activeAppId');

  const handleActionButton = () => {
    if (account) {
      history.push(`/apps/${activeAppId}/`);
    } else {
      history.push(AUTH_ROUTES.signIn);
    }
  };

  return {
    ...props,
    informationBlock: {
      logoWithHeader: {
        text: {
          value: t('404.header'),
        },
        lowerText: {
          value: t('404.content'),
        },
      },
      signUpButton: {
        text: {
          value: account ? t('404.spindl_button') : t('404.sign_in_button'),
        },
        onClick: handleActionButton,
      },
    },
  };
};

export default usePresenter;
