import cx from 'classnames';

import { VerticalTabItemCombinedProps } from './types';
import customStyles from './VerticalTabItemOverride.module.scss';

const usePresenter = (props: VerticalTabItemCombinedProps): VerticalTabItemCombinedProps => {
  return {
    ...props,
    className: cx(props.className, customStyles.verticalTabItem),
    classes: {
      ...props.classes,
      icon: cx(props.classes?.icon, customStyles.icon),
    },
  };
};

export default usePresenter;
