import cx from 'classnames';
import { useDropdownToggle } from 'spindl-shared-components';

import { AccountMenuDropDownCombinedProps } from './types';
import customStyles from './AccountMenuDropDownOverride.module.scss';


// MANUAL OVERRIDE
const usePresenter = (props: AccountMenuDropDownCombinedProps): AccountMenuDropDownCombinedProps => {
  const [toggleProps, { show }] = useDropdownToggle();
  const customStylesClassName = show ? customStyles.accountMenuDropDownExpanded : customStyles.accountMenuDropDownCollapsed;
  return {
    ...props,
    className: cx(props.className, customStylesClassName),
    classes: {
      ...props.classes,
      initials: cx(props.classes?.initials, customStyles.initials),
      name: cx(props.classes?.name, customStyles.name),
    },
    ...{
      ...toggleProps,
      onClick: (event?: React.MouseEvent<HTMLElement>) => {
        if (event) {
          toggleProps.onClick(event);
        }
      },
    },
    state: show ? 'Expanded' : 'Collapsed',
  };
};

export default usePresenter;
