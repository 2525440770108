import { matchPath, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { EDITOR_ROUTES } from '../../../lib/constants';
import { EditorLayoutCombinedProps } from './types';
import customStyles from './EditorLayoutOverride.module.scss';

const usePresenter = (props: EditorLayoutCombinedProps): EditorLayoutCombinedProps => {
  const { pathname } = useLocation();
  const isPreviewModule = matchPath(pathname, EDITOR_ROUTES.preview);
  return {
    ...props,
    className: cx(props.className, customStyles.editorLayout),
    classes: {
      content: cx(
        customStyles.content,
        isPreviewModule ? customStyles.showOverflow : customStyles.hideOverflow,
      ),
    },
    hideTopNav: !!isPreviewModule,
  };
};

export default usePresenter;
