import {
  ButtonProps,
  TextProps,
} from 'spindl-shared-components';

type PageHeaderInternalProps = {
  text?: TextProps;
  button?: ButtonProps;
};

type PageHeaderPropsValues = {
  '': PageHeaderInternalProps;
};

const propValues: PageHeaderPropsValues = {
  '': {
    text: {
      align: 'Left',
      styleType: 'SemiBold',
      colour: 'Default',
      type: 'Heading1',
    },
    button: {
      type: 'Text',
      size: 'Large',
      styleType: 'Primary',
      state: 'Default',
    },
  },
};

const getProps = (type: string): PageHeaderInternalProps => {
  const values: PageHeaderInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
