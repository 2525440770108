import {
  ButtonProps,
  TextFieldProps,
} from 'spindl-shared-components';
import { LogoWithHeaderProps } from '../../molecules/LogoWithHeader';

type ForgotPasswordBlockInternalProps = {
  button?: ButtonProps;
  logoWithHeader?: LogoWithHeaderProps;
  emailField?: TextFieldProps;
  signUpButton?: ButtonProps;
};

type ForgotPasswordBlockPropsValues = {
  '': ForgotPasswordBlockInternalProps;
};

const propValues: ForgotPasswordBlockPropsValues = {
  '': {
    button: {
      type: 'Icon',
      size: 'Medium',
      styleType: 'PrimarySubdued',
      state: 'Default',
    },
    logoWithHeader: {
      type: 'TitleWithDescription',
    },
    emailField: {
      state: 'Default',
      type: 'Text',
      error: 'None',
    },
    signUpButton: {
      type: 'Text',
      size: 'Large',
      styleType: 'Primary',
      state: 'Default',
    },
  },
};

const getProps = (type: string): ForgotPasswordBlockInternalProps => {
  const values: ForgotPasswordBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
