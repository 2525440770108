import {
  TextProps,
  IconProps,
  IconDropDownProps,
} from 'spindl-shared-components';
import { InitialsProps } from '../../atoms/Initials';
import { TitleWithDescriptionProps } from '../../molecules/TitleWithDescription';

type TeamCardInternalProps = {
  initials?: InitialsProps;
  iconDropDown?: IconDropDownProps;
  titleWithDescription?: TitleWithDescriptionProps;
  role?: TextProps;
  icon?: IconProps;
  email?: TextProps;
  status?: TextProps;
};

type TeamCardPropsValues = {
  'AddedButton': TeamCardInternalProps;
  'AddedNoButton': TeamCardInternalProps;
  'PendingButton': TeamCardInternalProps;
  'PendingNoButton': TeamCardInternalProps;
};

const propValues: TeamCardPropsValues = {
  'AddedButton': {
    initials: {
      type: 'Large',
    },
    iconDropDown: {
    },
    titleWithDescription: {
    },
    role: {
      align: 'Center',
      styleType: 'Regular',
      colour: 'Subdued',
      type: 'Body3',
    },
  },
  'AddedNoButton': {
    initials: {
      type: 'Large',
    },
    titleWithDescription: {
    },
    role: {
      align: 'Center',
      styleType: 'Regular',
      colour: 'Subdued',
      type: 'Body3',
    },
  },
  'PendingButton': {
    icon: {
      asset: 'Clock',
      colour: 'Subdued',
    },
    iconDropDown: {
    },
    email: {
      align: 'Center',
      styleType: 'Regular',
      colour: 'Subdued',
      type: 'Body3',
    },
    status: {
      align: 'Center',
      styleType: 'Regular',
      colour: 'Subdued',
      type: 'Body3',
    },
  },
  'PendingNoButton': {
    icon: {
      asset: 'Clock',
      colour: 'Subdued',
    },
    email: {
      align: 'Center',
      styleType: 'Regular',
      colour: 'Subdued',
      type: 'Body3',
    },
    status: {
      align: 'Center',
      styleType: 'Regular',
      colour: 'Subdued',
      type: 'Body3',
    },
  },
};

const getProps = (type: string): TeamCardInternalProps => {
  const values: TeamCardInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
