import axiosInstance from '../../lib/api/axios';
import { BFF_URL } from '../../lib/config';
import { AppInvite, AppInvitePayload, MemberInfo } from './types';

export const getMembersInfoByAppId = async (appId: number, myAccountId: number): Promise<MemberInfo[]> => {
  const { data } = await axiosInstance.get<MemberInfo[]>(`${BFF_URL}/apps/${appId}/members?accountId=${myAccountId}`);
  return data;
};

export const deleteTeamMember = async (appMemberId: number): Promise<void> => {
  await axiosInstance.delete(`${BFF_URL}/members/${appMemberId}`);
};

export const deleteInvite = async (inviteId: number): Promise<void> => {
  await axiosInstance.delete(`${BFF_URL}/apps/appInvitations/${inviteId}`);
};

export const getOpenInvites = async (appId: number): Promise<AppInvite[]> => {
  const { data } = await axiosInstance.get<AppInvite[]>(`${BFF_URL}/apps/${appId}/invites?state=created`);
  return data;
};

export const appInvite = async (appId: number, payload: AppInvitePayload): Promise<void> => {
  await axiosInstance.post(`${BFF_URL}/apps/${appId}/actions/invite`, payload);
};
