import { LeftNavProps } from '../../organisms/LeftNav';
import { TopNavProps } from '../../organisms/TopNav';
import { TeamsPageProps } from '../../pages/TeamsPage';


type MainLayoutInternalProps = {
  topNav?: TopNavProps;
  leftNav?: LeftNavProps;
  teamsPage?: TeamsPageProps;
};

type MainLayoutPropsValues = {
  '': MainLayoutInternalProps;
};

const propValues: MainLayoutPropsValues = {
  '': {
    topNav: {
    },
    leftNav: {
    },
    teamsPage: {
    },
  },
};

const getProps = (type: string): MainLayoutInternalProps => {
  const values: MainLayoutInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
