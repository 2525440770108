declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    env: any;
  }
}
  
// eslint-disable-next-line import/prefer-default-export
export const {
  REACT_APP_DOMAIN: DOMAIN = window.env?.REACT_APP_DOMAIN,
  REACT_APP_BFF_URL: BFF_URL = window.env?.REACT_APP_BFF_URL,
  REACT_APP_FRONTEND_URL: FRONTEND_URL = window.env?.REACT_APP_FRONTEND_URL,
  REACT_APP_SESSION_COOKIE_NAME: SESSION_COOKIE_NAME = window.env
    ?.REACT_APP_SESSION_COOKIE_NAME,
  REACT_APP_IS_DEV: IS_DEV = false,

  REACT_APP_SENTRY_ENV_FRONTEND: SENTRY_ENV_FRONTEND = window.env?.REACT_APP_SENTRY_ENV_FRONTEND,
  REACT_APP_SENTRY_DSN: SENTRY_DSN = window.env?.REACT_APP_SENTRY_DSN,
} = process.env;

export const SENTRY_TRACES_SAMPLE_RATE =
  Number(process.env?.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) ||
  Number(window.env?.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) ||
  1.0;
