import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { MembersContext } from '../../../modules/member/MembersContext';
import { TeamsPageCombinedProps } from './types';
import customStyles from './TeamsPageOverride.module.scss';

const usePresenter = (props: TeamsPageCombinedProps): TeamsPageCombinedProps => {
  const { updateModalState, modalState } = useContext(MembersContext);
  const { t } = useTranslation();
  
  return {
    ...props,
    modalState,
    pageHeader: {
      ...props.pageHeader,
      text: {
        value: t('teams_page.header'),
      },
      button: {
        text: {
          value: t('teams_page.button'),
        },
        onClick: () => updateModalState('INVITE_MODAL'),
      },
    },
    className: cx(props.className, customStyles.teamsPage),
    classes: {
      teamsBlock: customStyles.teamsBlock,
    },
  };
};

export default usePresenter;
