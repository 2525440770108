import React from 'react';
import cx from 'classnames';

import TeamCardList from '../../organisms/TeamCardList';

import styles from './TeamsBlock.module.scss';
import usePresenter from './TeamsBlock.presenter';
import getProps from './TeamsBlock.props';
import { TeamsBlockCombinedProps } from './types';
import { LoadingSpinner } from 'spindl-shared-components';

const TeamsBlock: React.FC<TeamsBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    teamCardList,
    loadingSpinner, // MANUAL OVERRIDE
    loading, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.teamsBlock;

  if (loading) {
    return (
      <div className={cx(styles.teamsBlockLoading)}>
        <LoadingSpinner
          {...internalProps.loadingSpinner}
          {...loadingSpinner}
        />
      </div>
    );
  }

  return (
    <div className={cx(currentStyle, className)}>
      <TeamCardList
        className={cx(styles.teamCardList, classes?.teamCardList)}
        {...internalProps.teamCardList}
        {...teamCardList}
      />
    </div>
  );
};

export default TeamsBlock;
