import React from 'react';
import cx from 'classnames';

import {
  Text,
  SpindlLogo,
} from 'spindl-shared-components';

import styles from './LogoWithHeader.module.scss';
import usePresenter from './LogoWithHeader.presenter';
import getProps from './LogoWithHeader.props';
import { LogoWithHeaderCombinedProps } from './types';

const LogoWithHeader: React.FC<LogoWithHeaderCombinedProps> = (props) => {
  const {
    type,
    className,
    classes,
    spindlLogo,
    text,
    lowerText,
  } = usePresenter(props);

  const variantName = `${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`logoWithHeader${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Title': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <SpindlLogo
              className={cx(styles.spindlLogo, classes?.spindlLogo)}
              {...internalProps.spindlLogo}
              {...spindlLogo}
            />
          </div>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
        </div>
      );
      break;
    }
    case 'TitleWithDescription': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <SpindlLogo
              className={cx(styles.spindlLogo, classes?.spindlLogo)}
              {...internalProps.spindlLogo}
              {...spindlLogo}
            />
          </div>
          <div className={cx(styles.bottomContent, classes?.bottomContent)}>
            <Text
              className={cx(styles.text, classes?.text)}
              {...internalProps.text}
              {...text}
            />
            <Text
              className={cx(styles.lowerText, classes?.lowerText)}
              {...internalProps.lowerText}
              {...lowerText}
            />
          </div>
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default LogoWithHeader;
