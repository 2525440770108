import {
  IconProps,
  TextProps,
} from 'spindl-shared-components';
import { InitialsProps } from '../../atoms/Initials';

type AccountMenuDropDownInternalProps = {
  initials?: InitialsProps;
  name?: TextProps;
  icon?: IconProps;
};

type AccountMenuDropDownPropsValues = {
  'Collapsed': AccountMenuDropDownInternalProps;
  'Expanded': AccountMenuDropDownInternalProps;
};

const propValues: AccountMenuDropDownPropsValues = {
  'Collapsed': {
    initials: {
      type: 'Small',
    },
    name: {
      align: 'Center',
      styleType: 'Regular',
      colour: 'Subdued',
      type: 'Body2',
    },
    icon: {
      asset: 'ChevronDown',
      colour: 'Default',
    },
  },
  'Expanded': {
    initials: {
      type: 'Small',
    },
    name: {
      align: 'Center',
      styleType: 'Regular',
      colour: 'Subdued',
      type: 'Body2',
    },
    icon: {
      asset: 'ChevronUp',
      colour: 'Default',
    },
  },
};

const getProps = (type: string): AccountMenuDropDownInternalProps => {
  const values: AccountMenuDropDownInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
