import {
  BorderProps,
} from 'spindl-shared-components';
import { VerticalTabListProps } from '../../molecules/VerticalTabList';

type LeftNavInternalProps = {
  serviceList?: VerticalTabListProps;
  settingsList?: VerticalTabListProps;
  border?: BorderProps;
};

type LeftNavPropsValues = {
  '': LeftNavInternalProps;
};

const propValues: LeftNavPropsValues = {
  '': {
    serviceList: {
    },
    settingsList: {
    },
    border: {
      colour: 'Dark',
      type: 'Vertical',
      styleType: 'Default',
    },
  },
};

const getProps = (type: string): LeftNavInternalProps => {
  const values: LeftNavInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
