import {
  TextProps,
  IconProps,
} from 'spindl-shared-components';

type AppDropDownInternalProps = {
  appName?: TextProps;
  icon?: IconProps;
};

type AppDropDownPropsValues = {
  'Collapsed': AppDropDownInternalProps;
  'Expanded': AppDropDownInternalProps;
};

const propValues: AppDropDownPropsValues = {
  'Collapsed': {
    appName: {
      align: 'Left',
      styleType: 'Regular',
      colour: 'Subdued',
      type: 'Body2',
    },
    icon: {
      asset: 'ChevronDown',
      colour: 'Subdued',
    },
  },
  'Expanded': {
    appName: {
      align: 'Left',
      styleType: 'Regular',
      colour: 'Default',
      type: 'Body2',
    },
    icon: {
      asset: 'ChevronUp',
      colour: 'Default',
    },
  },
};

const getProps = (type: string): AppDropDownInternalProps => {
  const values: AppDropDownInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
