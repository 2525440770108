import React from 'react';
import { DropDownMenu, DropDownMenuValueProps, DropdownWrapper, ExportedDropdownProps } from 'spindl-shared-components';

import InternalAccountMenuDropDown from './AccountMenuDropDown';
import { AccountMenuDropDownProps, AccountMenuDropDownValueProps as InternalAccountMenuDropDownValueProps } from './types';

export type { AccountMenuDropDownProps }; // MANUAL OVERRIDE

// MANUAL OVERRIDE
export type AccountMenuDropDownValueProps = ExportedDropdownProps<
InternalAccountMenuDropDownValueProps,
DropDownMenuValueProps
>;

// MANUAL OVERRIDE
const AccountMenuDropDown: React.FC<AccountMenuDropDownValueProps> = ({ id, toggleProps, menuProps }) => (
  <DropdownWrapper
    id={id}
    Toggle={InternalAccountMenuDropDown}
    Menu={DropDownMenu}
    toggleProps={toggleProps}
    menuProps={menuProps}
    alignEnd={true}
  />
);

export default AccountMenuDropDown;
