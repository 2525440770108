import { ForgotPasswordBlockProps } from '../../blocks/ForgotPasswordBlock';
import { InformationBlockProps } from '../../blocks/InformationBlock';
import { ResetPasswordBlockProps } from '../../blocks/ResetPasswordBlock';
import { SignInBlockProps } from '../../blocks/SignInBlock';
import { SignUpBlockProps } from '../../blocks/SignUpBlock';

type SignInPageInternalProps = {
  signInBlock?: SignInBlockProps;
  resetPasswordBlock?: ResetPasswordBlockProps; // MANUAL OVERRIDE
  forgotPasswordBlock?: ForgotPasswordBlockProps; // MANUAL OVERRIDE
  confirmationForgotPasswordBlock?: InformationBlockProps; // MANUAL OVERRIDE
  signUpBlock?: SignUpBlockProps; // MANUAL OVERRIDE
  invalidInviteBlock?: InformationBlockProps; // MANUAL OVERRIDE
  confirmationUpdatePasswordBlock?: InformationBlockProps; // MANUAL OVERRIDE
};

type SignInPagePropsValues = {
  '': SignInPageInternalProps;
};

const propValues: SignInPagePropsValues = {
  '': {
    signInBlock: {
    },
    resetPasswordBlock: { // MANUAL OVERRIDE
    },
    signUpBlock:{ // MANUAL OVERRIDE
    },
    forgotPasswordBlock: { // MANUAL OVERRIDE
    },
    confirmationForgotPasswordBlock: { // MANUAL OVERRIDE
    },
    confirmationUpdatePasswordBlock: { // MANUAL OVERRIDE
    },
    invalidInviteBlock: { // MANUAL OVERRIDE
    },
  },
};

const getProps = (type: string): SignInPageInternalProps => {
  const values: SignInPageInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
