import { SpindlServiceConfig } from './types';

export const SPINDL_SERVICES: SpindlServiceConfig[] = [
  {
    name: 'design',
    icon: 'Design',
  },
  {
    name: 'api-connector',
    icon: 'ApiConnector',
  },
  {
    name: 'identity',
    icon: 'UsersOutline',
  },
  {
    name: 'data',
    icon: 'DataOutline',
  },
  {
    name: 'communication',
    icon: 'Communication',
  },
];

export const AUTH_ROUTES = {
  signUp: '/auth/signUp',
  signIn: '/auth/signIn',
  forgotPassword: '/auth/forgotPassword',
  forgotPasswordConfirmed: '/auth/forgotPassword/confirmed',
  updatePassword: '/auth/updatePassword',
  updatedPasswordConfirmed: '/auth/updatePassword/confirmed',
  invalidInvite: '/auth/invalidInvite',
};

export const BASE_SERVICE_ROUTES = {
  users: '/apps/:appId/users',
  communication: '/apps/:appId/communication',
  data: '/apps/:appId/data',
  team: '/apps/:appId/team',
  design: '/apps/:appId/design',
  'api-connector': '/apps/:appId/api-connector',
};

export const EDITOR_ROUTES = {
  communication: '/apps/:appId/:activePage(communication)/editor/:templateId',
  data: '/apps/:appId/:activePage(data)/database/:databaseSlug/(table)?/:tableSlug?',
  preview: '/apps/:appId/preview',
};

export const NOTIFICATION_DURATION = 3000;
