import { TeamCardProps } from '../TeamCard';


type TeamCardListInternalProps = {
  teamCard?: TeamCardProps;
};

type TeamCardListPropsValues = {
  '': TeamCardListInternalProps;
};

const propValues: TeamCardListPropsValues = {
  '': {
    teamCard: {
      state: 'Added',
      type: 'Button',
    },
  },
};

const getProps = (type: string): TeamCardListInternalProps => {
  const values: TeamCardListInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
