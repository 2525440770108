export const isEmptyString = (value?: string) => (value ? value.trim().length === 0 : true);

export const isEmail = (value: string) => {
  // eslint-disable-next-line
  const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexEmail.test(value);
};

export const getInitials = (firstName: string, lastName: string): string => {
  return firstName[0] + lastName[0];
};
