import { getMyUser, getLoginToken } from '../../../modules/auth/auth.repository';
import { AccountResponse, SignInPayload } from '../../../modules/auth/types';
import { User } from '../../../modules/user/types';

export const getLoginTokenUseCase = async (data: SignInPayload): Promise<AccountResponse> => {
  return getLoginToken(data);
};

export const getMyUserUseCase = async (): Promise<User> => {
  return getMyUser();
};
