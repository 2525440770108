import React from 'react';
import cx from 'classnames';

import {
  Text,
  Icon,
} from 'spindl-shared-components';

import styles from './AppDropDown.module.scss';
import usePresenter from './AppDropDown.presenter';
import getProps from './AppDropDown.props';
import { AppDropDownCombinedProps } from './types';

// MANUAL OVERRIDE - converted to forwardRef Component
const AppDropDown = React.forwardRef<HTMLButtonElement, AppDropDownCombinedProps>((props, ref) => {
  const {
    state,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    appImage,
    appName,
    icon,
    border,
    ...otherProps // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`appDropDown${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed':
    case 'Expanded': {
      componentView = (
        <button
          ref={ref} // MANUAL OVERRIDE
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}
          {...otherProps} // MANUAL OVERRIDE
        >
          <div className={cx(styles.content, classes?.content)}>
            <Text
              className={cx(styles.appName, classes?.appName)}
              {...internalProps.appName}
              {...appName}
            />
            <Icon
              className={cx(styles.icon, classes?.icon)}
              {...internalProps.icon}
              {...icon}
            />
          </div>
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
}); // MANUAL OVERRIDE

export default AppDropDown;
