import React from 'react';
import cx from 'classnames';

import {
  Button,
  Text,
} from 'spindl-shared-components';

import styles from './PageHeader.module.scss';
import usePresenter from './PageHeader.presenter';
import getProps from './PageHeader.props';
import { PageHeaderCombinedProps } from './types';

const PageHeader: React.FC<PageHeaderCombinedProps> = (props) => {
  const {
    className,
    classes,
    text,
    button,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.pageHeader;

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={cx(styles.text, classes?.text)}
        {...internalProps.text}
        {...text}
      />
      <Button
        className={cx(styles.button, classes?.button)}
        {...internalProps.button}
        {...button}
      />
    </div>
  );
};

export default PageHeader;
