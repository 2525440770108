import { useTranslation } from 'react-i18next';
import { useAuth } from 'spindl-shared-components';
import cx from 'classnames';

import { logout } from '../../../modules/auth/utils';
import { AUTH_ROUTES } from '../../../lib/constants';
import { getInitials } from '../../../lib/utils';
import { useAppDropdown } from '../../molecules/AppDropDown/useAppDropdown';
import { TopNavCombinedProps } from './types';
import customStyles from './TopNavOverride.module.scss';

const usePresenter = (props: TopNavCombinedProps): TopNavCombinedProps => {
  const { account } = useAuth();
  const { t } = useTranslation();
  const appDropDownProps = useAppDropdown();

  const signOut = () => {
    logout();
    window.location.href = AUTH_ROUTES.signIn;
  };

  if (!account) {
    return props;
  }
  
  return {
    ...props,
    className: cx(props.className, customStyles.topNav),
    classes: {
      spindlLogo: customStyles.spindlLogo,
    },
    appDropDown: {
      menuProps: appDropDownProps.menuProps,
      toggleProps: appDropDownProps.toggleProps,
    },
    accountMenuDropDown: {
      toggleProps: {
        initials: {
          text: {
            dataTestId: 'initials-text',
            value: getInitials(account.firstName, account.lastName),
          },
        },
        name: {
          dataTestId: 'name-text',
          value: `${account?.firstName} ${account?.lastName}`,
        },
      },
      menuProps: {
        dropDownList: {
          dropDownItems: [
            {
              type: 'DefaultIconText',
              icon: {
                asset: 'SignOut',
              },
              text: {
                dataTestId: 'sign-out-button',
                value: t('top_nav.sign_out_button'),
                colour: 'Subdued',
              },
              onClick: signOut,
            },
          ],
        },
      },
    },
  };
};

export default usePresenter;
