import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AUTH_ROUTES } from '../../../lib/constants';
import { isEmail, isEmptyString } from '../../../lib/utils';
import { setForgotPasswordUseCase } from './ForgotPasswordBlock.interactor';
import { ForgotPasswordBlockCombinedProps } from './types';
import { TextFieldErrorEnum } from '../../../lib/types';

const usePresenter = (props: ForgotPasswordBlockCombinedProps): ForgotPasswordBlockCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [emailErrorState, setEmailErrorState] = useState<TextFieldErrorEnum>('None');
  const [emailErrorText, setEmailErrorText] = useState<string>('');
  const formInvalid = (isEmptyString(email));

  const handleEmail = (event: string) => {
    setEmail(event);
  };

  const handleBackButton = () => {
    history.push('/');
  };

  const handleForgotPassword = async () => {
    setEmailErrorState('None');
    setEmailErrorText('');
    const emailValue = email.trim().toLowerCase();
    if (isEmail(emailValue)) {
      try {
        await setForgotPasswordUseCase({ email: emailValue });
      } catch {
        // no-op
      }
      history.push(AUTH_ROUTES.forgotPasswordConfirmed);
    } else {
      const errorString = t('forgot_password_block.invalid_email');
      setEmailErrorState('Error');
      setEmailErrorText(errorString);
    }
  };

  return {
    ...props,
    button: {
      onClick: handleBackButton,
    },
    logoWithHeader: {
      text: {
        value: t('forgot_password_block.title'),
      },
      lowerText: {
        value: t('forgot_password_block.description'),
      },
    },
    emailField: {
      label: {
        value: t('forgot_password_block.text_field_label'),
      },
      textInput: {
        textPlaceholder: t('forgot_password_block.placeholder'),
        onTextChanged: handleEmail,
        inputType: 'email',
      },
      text: {
        dataTestId: 'email-error',
        value: emailErrorText,
      },
      error: emailErrorState,
    },
    signUpButton: {
      text: {
        value: t('forgot_password_block.button_label'),
      },
      onClick: handleForgotPassword,
      disabled: formInvalid,
      buttonType: 'submit',
    },
  };
};

export default usePresenter;
