import { SignInPageProps } from '../../pages/SignInPage';


type SignInLayoutInternalProps = {
  signInPage?: SignInPageProps;
};

type SignInLayoutPropsValues = {
  '': SignInLayoutInternalProps;
};

const propValues: SignInLayoutPropsValues = {
  '': {
    signInPage: {
    },
  },
};

const getProps = (type: string): SignInLayoutInternalProps => {
  const values: SignInLayoutInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
