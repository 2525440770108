import React from 'react';
import cx from 'classnames';
import { Route, Switch } from 'react-router-dom';

import { CommunicationModule } from 'spindl-communication-mod';
import { DataModule } from 'spindl-data-mod';
import { ApiConnectorModule, DesignModule } from 'spindl-studio-mod';
import { UserModule } from 'spindl-user-mod';
import LeftNav from '../../organisms/LeftNav';
import TopNav from '../../organisms/TopNav';
import TeamsPage from '../../pages/TeamsPage';

import styles from './MainLayout.module.scss';
import usePresenter from './MainLayout.presenter';
import getProps from './MainLayout.props';
import { MainLayoutCombinedProps } from './types';
import { BASE_SERVICE_ROUTES } from '../../../lib/constants';

const MainLayout: React.FC<MainLayoutCombinedProps> = (props) => {
  const {
    className,
    classes,
    topNav,
    customTopNav, // MANUAL OVERRIDE
    CustomTopNav, // MANUAL OVERRIDE
    leftNav,
    teamsPage,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.mainLayout;

  // MANUAL OVERRIDE
  const topNavView = CustomTopNav ? (
    <CustomTopNav
      className={cx(styles.topNav, classes?.topNav)}
      {...customTopNav}
    />
  ) : (
    <TopNav
      className={cx(styles.topNav, classes?.topNav)}
      {...internalProps.topNav}
      {...topNav}
    />
  );

  return (
    <div className={cx(currentStyle, className)}>
      {/* MANUAL OVERRIDE */}
      {topNavView}

      <div className={cx(styles.content, classes?.content)}>
        <LeftNav
          className={cx(styles.leftNav, classes?.leftNav)}
          {...internalProps.leftNav}
          {...leftNav}
        />

        {/* MANUAL OVERRIDE */}
        <div style={{ width: '100%', height: '100%' }}>
          <Switch>
            {/* Team page */}
            <Route path={BASE_SERVICE_ROUTES.team}>
              <TeamsPage
                className={cx(styles.teamsPage, classes?.teamsPage)}
                {...internalProps.teamsPage}
                {...teamsPage}
              />
            </Route>

            {/* Spindl services */}
            <Route path={BASE_SERVICE_ROUTES.users} component={UserModule} />
            <Route path={BASE_SERVICE_ROUTES.communication} component={CommunicationModule} />
            <Route path={BASE_SERVICE_ROUTES.data} component={DataModule} />
            <Route path={BASE_SERVICE_ROUTES.design} component={DesignModule} />
            <Route path={BASE_SERVICE_ROUTES['api-connector']} component={ApiConnectorModule} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
