import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import './index.css';
import App from './App';
import AppProvider from './AppProvider';
import reportWebVitals from './reportWebVitals';
import { SENTRY_ENV_FRONTEND, SENTRY_DSN, SENTRY_TRACES_SAMPLE_RATE } from './lib/config';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV_FRONTEND,
  integrations: [new BrowserTracing()],
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,

  // Manually ignored errors and URLs
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
