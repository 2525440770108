import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import useUpdateAccount from '../../../modules/auth/useUpdateAccount';
import { decodeInviteToken } from '../../../lib/tokenManager';
import { SignUpPayload } from '../../../modules/auth/types';
import { AUTH_ROUTES } from '../../../lib/constants';
import { isEmptyString } from '../../../lib/utils';
import { signUpUseCase } from './SignUpBlock.interactor';
import { SignUpBlockCombinedProps } from './types';

type SignUpState = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const SignUpInitialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const usePresenter = (props: SignUpBlockCombinedProps): SignUpBlockCombinedProps => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const { updateAccount } = useUpdateAccount();

  const [signUpState, setSignUpState] = useState<SignUpState>(SignUpInitialState);
  const [signUpErrorState, setSignUpErrorState] = useState<SignUpState>(SignUpInitialState);

  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(true);

  if (token) {
    const payload = decodeInviteToken(token);
    if (payload) {
      signUpState.email = payload.email;
      if (Date.now() >= payload.exp * 1000) {
        history.push(AUTH_ROUTES.invalidInvite);
      }
    } else {
      history.push(AUTH_ROUTES.invalidInvite);
    }
  } else {
    history.push(AUTH_ROUTES.invalidInvite);
  }

  const handleTextChange = (field: keyof SignUpState) => {
    return (value: string) => {
      setSignUpState({
        ...signUpState,
        [field]: value,
      });
    };
  };

  useEffect(() => {
    setSignUpErrorState(SignUpInitialState);
    if (isEmptyString(signUpState.firstName) &&
      isEmptyString(signUpState.lastName) &&
      isEmptyString(signUpState.password) &&
      isEmptyString(signUpState.confirmPassword)) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [signUpState]);

  const validForm = () => {
    const invalidFormErrors = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    };

    if (isEmptyString(signUpState.firstName) ||
      isEmptyString(signUpState.lastName) ||
      isEmptyString(signUpState.password) ||
      isEmptyString(signUpState.confirmPassword)) {
      if (isEmptyString(signUpState.firstName)) {
        invalidFormErrors.firstName = t('sign_up_block.error_messages.missing_first_name');
      }
      if (isEmptyString(signUpState.lastName)) {
        invalidFormErrors.lastName = t('sign_up_block.error_messages.missing_last_name');
      }
      if (isEmptyString(signUpState.password)) {
        invalidFormErrors.password = t('sign_up_block.error_messages.missing_password');
      }
      if (isEmptyString(signUpState.confirmPassword)) {
        invalidFormErrors.confirmPassword = t('sign_up_block.error_messages.missing_password');
      }
      setSignUpErrorState(invalidFormErrors);
      return false;
    }

    if (signUpState.password !== signUpState.confirmPassword) {
      invalidFormErrors.password = t('sign_up_block.error_messages.passwords_mismatch');
      invalidFormErrors.confirmPassword = t('sign_up_block.error_messages.passwords_mismatch');
      setSignUpErrorState(invalidFormErrors);
      return false;
    }

    if (signUpState.password.length < 8) {
      invalidFormErrors.password = t('sign_up_block.error_messages.weak_password');
      invalidFormErrors.confirmPassword = t('sign_up_block.error_messages.weak_password');
      setSignUpErrorState(invalidFormErrors);
      return false;
    }

    return true;
  };

  const signUpMutation = useMutation((payload: SignUpPayload) => {
    return signUpUseCase(payload);
  });

  const handleSignUp = async () => {
    if (!validForm()) {
      return;
    }
    if (token) {
      const res = await signUpMutation.mutateAsync({
        firstName: signUpState.firstName,
        lastName: signUpState.lastName,
        email: signUpState.email,
        password: signUpState.password,
        enabled: true,
        inviteToken: token,
      });
      updateAccount({ ...res });
      history.push('/');
    }
  };

  const handleSignIn = () => {
    history.push(AUTH_ROUTES.signIn);
  };

  return {
    ...props,
    logoWithHeader: {
      text: {
        value: t('sign_up_block.description'),
      },
    },
    nameField: {
      leftTextField: {
        label: {
          value: t('sign_up_block.first_name'),
        },
        textInput: {
          onTextChanged: handleTextChange('firstName'),
          dataTestId: 'first-name-input',
        },
        error: signUpErrorState.firstName === '' ? 'None' : 'Error',
        text: {
          value: signUpErrorState.firstName,
          dataTestId: 'first-name-error',
        },
      },
      rightTextField: {
        label: {
          value: t('sign_up_block.last_name'),
        },
        textInput: {
          onTextChanged: handleTextChange('lastName'),
          dataTestId: 'last-name-input',
        },
        error: signUpErrorState.lastName === '' ? 'None' : 'Error',
        text: {
          dataTestId: 'last-name-error',
          value: signUpErrorState.lastName,
        },
      },
    },
    emailField: {
      label: {
        value: t('sign_up_block.email'),
      },
      textInput: {
        textValue: signUpState.email ?? '',
        dataTestId: 'email-input',
        disabled: signUpState.email !== '',
      },
    },
    passwordField: {
      label: {
        value: t('sign_up_block.create_password'),
      },
      textInput: {
        inputType: passwordShown ? 'text' : 'password',
        button: {
          onClick: () => setPasswordShown(!passwordShown),
          icon: {
            asset: passwordShown ? 'HidePassword' : 'ShowPassword',
          },
          dataTestId: 'password-button',
          buttonType: 'button',
        },
        onTextChanged: handleTextChange('password'),
        dataTestId: 'password-input',
      },
      error: signUpErrorState.password === '' ? 'None' : 'Error',
      text: {
        value: signUpErrorState.password,
        dataTestId: 'password-error',
      },
    },
    confirmPasswordField: {
      label: {
        value: t('sign_up_block.confirm_password'),
      },
      textInput: {
        inputType: confirmPasswordShown ? 'text' : 'password',
        button: {
          onClick: () => setConfirmPasswordShown(!confirmPasswordShown),
          icon: {
            asset: confirmPasswordShown ? 'HidePassword' : 'ShowPassword',
          },
          dataTestId: 'confirm-password-button',
          buttonType: 'button',
        },
        onTextChanged: handleTextChange('confirmPassword'),
        dataTestId: 'confirm-password-input',
      },
      error: signUpErrorState.confirmPassword === '' ? 'None' : 'Error',
      text: {
        value: signUpErrorState.confirmPassword,
        dataTestId: 'confirm-password-error',
      },
    },
    signUpButton: {
      text: {
        value: t('sign_up_block.button'),
      },
      disabled: disableButton,
      dataTestId: 'sign-up-button',
      onClick: handleSignUp,
    },
    text: {
      value: t('sign_up_block.sign_in_prompt'),
    },
    button: {
      text: {
        value: t('sign_up_block.sign_in'),
      },
      onClick: handleSignIn,
      dataTestId: 'sign-in-option',
    },
  };
};

export default usePresenter;
