import axiosInstance from '../../lib/api/axios';
import { BFF_URL } from '../../lib/config';
import { User } from '../user/types';
import {
  AccountResponse, ForgotPasswordPayload, SignInPayload, SignUpPayload, 
  UpdatePasswordPayload,
} from './types';

export const getLoginToken = async (payload: SignInPayload): Promise<AccountResponse> => {
  const { data } = await axiosInstance.post(`${BFF_URL}/auth/signIn`, payload);
  return data;
};

export const getMyUser = async (): Promise<User> => {
  const { data } = await axiosInstance.get<User>(`${BFF_URL}/users/me`);
  return data;
};

export const signUp = async (payload: SignUpPayload): Promise<AccountResponse> => {
  const { data } = await axiosInstance.post<AccountResponse>(`${BFF_URL}/auth/signUp`, payload);
  return data;
};

export const forgotPassword = async (payload: ForgotPasswordPayload): Promise<void> => {
  await axiosInstance.post(`${BFF_URL}/auth/forgotPassword`, payload);
};

export const updatePassword = async (payload: UpdatePasswordPayload): Promise<void> => {
  await axiosInstance.patch(`${BFF_URL}/auth/updatePassword`, payload);
};
