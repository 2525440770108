import {
  TextProps,
} from 'spindl-shared-components';

type TitleWithDescriptionInternalProps = {
  title?: TextProps;
  subtitle?: TextProps;
};

type TitleWithDescriptionPropsValues = {
  '': TitleWithDescriptionInternalProps;
};

const propValues: TitleWithDescriptionPropsValues = {
  '': {
    title: {
      align: 'Center',
      styleType: 'SemiBold',
      colour: 'Default',
      type: 'Heading3',
    },
    subtitle: {
      align: 'Center',
      styleType: 'Regular',
      colour: 'Subdued',
      type: 'Body3',
    },
  },
};

const getProps = (type: string): TitleWithDescriptionInternalProps => {
  const values: TitleWithDescriptionInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
