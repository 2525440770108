import {
  TextFieldProps,
  ButtonProps,
} from 'spindl-shared-components';
import { LogoWithHeaderProps } from '../../molecules/LogoWithHeader';

type SignInBlockInternalProps = {
  logoWithHeader?: LogoWithHeaderProps;
  emailField?: TextFieldProps;
  passwordField?: TextFieldProps;
  forgotPasswordButton?: ButtonProps;
  signInButton?: ButtonProps; // FIXME: rename
};

type SignInBlockPropsValues = {
  '': SignInBlockInternalProps;
};

const propValues: SignInBlockPropsValues = {
  '': {
    logoWithHeader: {
      type: 'Title',
    },
    emailField: {
      state: 'Default',
      type: 'Text',
      error: 'None',
    },
    passwordField: {
      state: 'Default',
      type: 'Password',
      error: 'None',
    },
    forgotPasswordButton: {
      type: 'Text',
      size: 'Small',
      styleType: 'PrimaryGhost',
      state: 'Default',
    },
    signInButton: {
      type: 'Text',
      size: 'Large',
      styleType: 'Primary',
      state: 'Default',
    },
  },
};

const getProps = (type: string): SignInBlockInternalProps => {
  const values: SignInBlockInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
