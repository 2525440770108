import React from 'react';
import cx from 'classnames';

import {
  Border,
} from 'spindl-shared-components';
import VerticalTabList from '../../molecules/VerticalTabList';

import styles from './LeftNav.module.scss';
import usePresenter from './LeftNav.presenter';
import getProps from './LeftNav.props';
import { LeftNavCombinedProps } from './types';

const LeftNav: React.FC<LeftNavCombinedProps> = (props) => {
  const {
    className,
    classes,
    serviceList,
    settingsList,
    border,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.leftNav;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.menuContent, classes?.menuContent)}>
        <div className={cx(styles.serviceMenu, classes?.serviceMenu)}>
          <VerticalTabList
            className={cx(styles.serviceList, classes?.serviceList)}
            {...internalProps.serviceList}
            {...serviceList}
          />
        </div>
        <div className={cx(styles.settingsMenu, classes?.settingsMenu)}>
          <VerticalTabList
            className={cx(styles.settingsList, classes?.settingsList)}
            {...internalProps.settingsList}
            {...settingsList}
          />
        </div>
      </div>
      <Border
        className={cx(styles.border, classes?.border)}
        {...internalProps.border}
        {...border}
      />
    </div>
  );
};

export default LeftNav;
