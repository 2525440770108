import React from 'react';
import cx from 'classnames';

import {
  Border,
  Button,
  Text,
} from 'spindl-shared-components';
import TeamCard from '../TeamCard';

import styles from './DeleteTeammateModal.module.scss';
import usePresenter from './DeleteTeammateModal.presenter';
import getProps from './DeleteTeammateModal.props';
import { DeleteTeammateModalCombinedProps } from './types';

const DeleteTeammateModal: React.FC<DeleteTeammateModalCombinedProps> = (props) => {
  const {
    className,
    classes,
    title,
    closeButton,
    border,
    message,
    teamCard,
    deleteButton,
    cancelButton,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.deleteTeammateModal;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <div className={cx(styles.content, classes?.content)}>
          <Text
            className={cx(styles.title, classes?.title)}
            {...internalProps.title}
            {...title}
          />
          <Button
            className={cx(styles.closeButton, classes?.closeButton)}
            {...internalProps.closeButton}
            {...closeButton}
          />
        </div>
        <Border
          className={cx(styles.border, classes?.border)}
          {...internalProps.border}
          {...border}
        />
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Text
          className={cx(styles.message, classes?.message)}
          {...internalProps.message}
          {...message}
        />
        <TeamCard
          className={cx(styles.teamCard, classes?.teamCard)}
          {...internalProps.teamCard}
          {...teamCard}
        />
        <div className={cx(styles.buttonPair, classes?.buttonPair)}>
          <Button
            className={cx(styles.deleteButton, classes?.deleteButton)}
            {...internalProps.deleteButton}
            {...deleteButton}
          />
          <Button
            className={cx(styles.cancelButton, classes?.cancelButton)}
            {...internalProps.cancelButton}
            {...cancelButton}
          />
        </div>
      </div>
    </div>
  );
};

export default DeleteTeammateModal;
