import React from 'react';
import cx from 'classnames';

import TeamsBlock from '../../blocks/TeamsBlock';
import DeleteInviteModal from '../../organisms/DeleteInviteModal';
import DeleteTeammateModal from '../../organisms/DeleteTeammateModal';
import PageHeader from '../../organisms/PageHeader';
import TeammateInviteModal from '../../organisms/TeammateInviteModal';

import styles from './TeamsPage.module.scss';
import usePresenter from './TeamsPage.presenter';
import getProps from './TeamsPage.props';
import { TeamsPageCombinedProps } from './types';

const TeamsPage: React.FC<TeamsPageCombinedProps> = (props) => {
  const {
    className,
    classes,
    pageHeader,
    teamsBlock,
    modalState, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.teamsPage;

  return (
    <div className={cx(currentStyle, className)}>
      {/* MANUAL OVERRIDE */}
      <TeammateInviteModal
        className={styles.modalWrapper}
        show={modalState === 'INVITE_MODAL'}
      />
      {/* MANUAL OVERRIDE */}
      <DeleteTeammateModal 
        className={styles.modalWrapper}
        show={modalState === 'DELETE_MODAL'}
      />
      {/* MANUAL OVERRIDE */}
      <DeleteInviteModal
        className={styles.modalWrapper}
        show={modalState === 'DELETE_INVITE_MODAL'}
      />
      <PageHeader
        className={cx(styles.pageHeader, classes?.pageHeader)}
        {...internalProps.pageHeader}
        {...pageHeader}
      />
      <div className={cx(styles.pageContainer, classes?.pageContainer)}>
        <TeamsBlock
          className={cx(styles.teamsBlock, classes?.teamsBlock)}
          {...internalProps.teamsBlock}
          {...teamsBlock}
        />
      </div>
    </div>
  );
};

export default TeamsPage;
