import React from 'react';
import cx from 'classnames';

import {
  Button,
  Border,
  TextField,
  Text,
} from 'spindl-shared-components';

import styles from './TeammateInviteModal.module.scss';
import usePresenter from './TeammateInviteModal.presenter';
import getProps from './TeammateInviteModal.props';
import { TeammateInviteModalCombinedProps } from './types';

const TeammateInviteModal: React.FC<TeammateInviteModalCombinedProps> = (props) => {
  const {
    className,
    classes,
    title,
    closeButton,
    border,
    emailField,
    addButton,
    add,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.teammateInviteModal;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <div className={cx(styles.content, classes?.content)}>
          <Text
            className={cx(styles.title, classes?.title)}
            {...internalProps.title}
            {...title}
          />
          <Button
            className={cx(styles.closeButton, classes?.closeButton)}
            {...internalProps.closeButton}
            {...closeButton}
          />
        </div>
        <Border
          className={cx(styles.border, classes?.border)}
          {...internalProps.border}
          {...border}
        />
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <TextField
          className={cx(styles.emailField, classes?.emailField)}
          {...internalProps.emailField}
          {...emailField}
        />
        <Button
          className={cx(styles.addButton, classes?.addButton)}
          {...internalProps.addButton}
          {...addButton}
        />
        <Button
          className={cx(styles.add, classes?.add)}
          {...internalProps.add}
          {...add}
        />
      </div>
    </div>
  );
};

export default TeammateInviteModal;
