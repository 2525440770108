import React, { createContext, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../user';
import { getApps } from './apps.repository';
import { App } from './types';

export type AppsContextValue = {
  apps?: App[];
  refetchApps?: () => void;
  activeApp?: App;
  updateActiveApp: (activeApp?: App) => void;
  isLoading: boolean;
  isError: boolean;
};

export const initialAppsContext: AppsContextValue = {
  apps: [],
  refetchApps: () => {},
  activeApp: undefined,
  updateActiveApp: () => {},
  isLoading: true,
  isError: false,
};

export const AppsContext = createContext<AppsContextValue>(initialAppsContext);

export const AppsProvider: React.FC<{}> = ({ children }) => {
  const [activeApp, setActiveApp] = useState<App | undefined>(undefined);
  const { user } = useContext(UserContext);

  // The query will only be enabled when user.id is defined, so we can safely use the non-null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: apps, isLoading, isError, refetch: refetchApps } = useQuery('getApps', () => getApps(user!.id), {
    enabled: !!user && !!user.id,
  });

  const updateActiveApp = (newActiveApp?: App) => {
    setActiveApp(newActiveApp);
    if (newActiveApp) {
      localStorage.setItem('activeAppId', newActiveApp.id.toString());
    }
  };

  return (
    <AppsContext.Provider value={{
      apps,
      refetchApps,
      activeApp,
      updateActiveApp,
      isLoading,
      isError,
    }}>
      {children}
    </AppsContext.Provider>
  );
};
