import { ExportedModalProps, ModalWrapper } from 'spindl-shared-components';
import InternalDeleteInviteModal from './DeleteInviteModal';
import {
  DeleteInviteModalProps,
  DeleteInviteModalValueProps as InternalDeleteInviteModalValueProps,
} from './types';

export type { DeleteInviteModalProps }; // MANUAL OVERRIDE

// MANUAL OVERRIDE
export type DeleteInviteModalValueProps = ExportedModalProps<InternalDeleteInviteModalValueProps>;

// MANUAL OVERRIDE
const DeleteInviteModal: React.FC<DeleteInviteModalValueProps> = ({ className, onHide, show, modalProps }) => (
  <ModalWrapper
    className={className}
    content={<InternalDeleteInviteModal {...modalProps} />}
    onHide={onHide}
    show={show}
  />
);

export default DeleteInviteModal;
