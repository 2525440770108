import cx from 'classnames';
import { useDropdownToggle } from 'spindl-shared-components';

import { AppDropDownCombinedProps } from './types';
import customStyles from './AppDropDownOverride.module.scss';

// MANUAL OVERRIDE
const usePresenter = (props: AppDropDownCombinedProps): AppDropDownCombinedProps => {
  const [toggleProps, { show }] = useDropdownToggle();
  return {
    ...props,
    ...{
      ...toggleProps,
      onClick: (event?: React.MouseEvent<HTMLElement>) => {
        if (event) {
          toggleProps.onClick(event);
        }
      },
    },
    className: cx(props.className, customStyles.appDropDown),
    classes: {
      ...props.classes,
      appName: cx(props.classes?.appName, customStyles.appName),
      icon: cx(props.classes?.icon, customStyles.icon),
    },
    state: show ? 'Expanded' : 'Collapsed',
  };
};

export default usePresenter;
