import {
  IconProps,
} from 'spindl-shared-components';

type VerticalTabItemInternalProps = {
  icon?: IconProps;
};

type VerticalTabItemPropsValues = {
  'Selected': VerticalTabItemInternalProps;
  'Unselected': VerticalTabItemInternalProps;
};

const propValues: VerticalTabItemPropsValues = {
  'Selected': {
    icon: {
      asset: 'Plus',
      colour: 'Default',
    },
  },
  'Unselected': {
    icon: {
      asset: 'Plus',
      colour: 'Subdued',
    },
  },
};

const getProps = (type: string): VerticalTabItemInternalProps => {
  const values: VerticalTabItemInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
