import React from 'react';
import { useAuth } from 'spindl-shared-components';
import { Redirect, RouteProps, Route, useLocation } from 'react-router-dom';
import { AUTH_ROUTES } from '../../lib/constants';

const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const { account } = useAuth();
  const { pathname } = useLocation();

  if (!account) {
    return (
      <Redirect
        to={{
          pathname: AUTH_ROUTES.signIn,
          // Set the current pathname as return_to
          search: `?return_to=${encodeURIComponent(pathname)}`,
        }}
      />
    );
  }

  return <Route {...props} />; // If no errors then proceed to the route
};

export default ProtectedRoute;
