import React from 'react';
import cx from 'classnames';

import {
  Button,
  TextField,
  PairTextFields,
  Text,
} from 'spindl-shared-components';
import LogoWithHeader from '../../molecules/LogoWithHeader';

import styles from './SignUpBlock.module.scss';
import usePresenter from './SignUpBlock.presenter';
import getProps from './SignUpBlock.props';
import { SignUpBlockCombinedProps } from './types';

const SignUpBlock: React.FC<SignUpBlockCombinedProps> = (props) => {
  const {
    className,
    classes,
    logoWithHeader,
    nameField,
    emailField,
    passwordField,
    confirmPasswordField,
    signUpButton,
    text,
    button,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.signUpBlock;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.topContent, classes?.topContent)}>
        <LogoWithHeader
          className={cx(styles.logoWithHeader, classes?.logoWithHeader)}
          {...internalProps.logoWithHeader}
          {...logoWithHeader}
        />
        <PairTextFields
          className={cx(styles.nameField, classes?.nameField)}
          {...internalProps.nameField}
          {...nameField}
        />
        <TextField
          className={cx(styles.emailField, classes?.emailField)}
          {...internalProps.emailField}
          {...emailField}
        />
        <TextField
          className={cx(styles.passwordField, classes?.passwordField)}
          {...internalProps.passwordField}
          {...passwordField}
        />
        <TextField
          className={cx(styles.confirmPasswordField, classes?.confirmPasswordField)}
          {...internalProps.confirmPasswordField}
          {...confirmPasswordField}
        />
      </div>
      <div className={cx(styles.bottomContent, classes?.bottomContent)}>
        <Button
          className={cx(styles.signUpButton, classes?.signUpButton)}
          {...internalProps.signUpButton}
          {...signUpButton}
        />
        <div className={cx(styles.signInOption, classes?.signInOption)}>
          <Text
            className={cx(styles.text, classes?.text)}
            {...internalProps.text}
            {...text}
          />
          <Button
            className={cx(styles.button, classes?.button)}
            {...internalProps.button}
            {...button}
          />
        </div>
      </div>
    </div>
  );
};

export default SignUpBlock;
