import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import useUpdateAccount from '../../../modules/auth/useUpdateAccount';
import { AUTH_ROUTES } from '../../../lib/constants';
import { isEmail, isEmptyString } from '../../../lib/utils';
import { SignInPayload } from '../../../modules/auth/types';
import { getLoginTokenUseCase } from './SignInBlock.interactor';
import { SignInBlockCombinedProps } from './types';
import { TextFieldErrorEnum } from '../../../lib/types';

type SignInState = {
  email: string;
  password: string;
};

const SignInInitialState = {
  email: '',
  password: '',
};

const usePresenter = (props: SignInBlockCombinedProps): SignInBlockCombinedProps => {
  const { t } = useTranslation();
  const history = useHistory();
  const { updateAccount } = useUpdateAccount();

  // Get the return_to search parameter
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const returnTo = query.get('return_to');

  const [signInState, setSignInState] = useState<SignInState>(SignInInitialState);
  const [signInErrorState, setSignInErrorState] = useState<SignInState>(SignInInitialState);
  const [emailInputErrorState, setEmailInputErrorState] = useState<TextFieldErrorEnum>('None');
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const formInvalid = (isEmptyString(signInState.email) || isEmptyString(signInState.password));

  const handleTextChange = (field: keyof SignInState) => {
    return (value: string) => {
      setSignInState({
        ...signInState,
        [field]: value,
      });
    };
  };

  const handleForgotPassword = () => {
    history.push(AUTH_ROUTES.forgotPassword);
  };

  const signInMutation = useMutation((payload: SignInPayload) => {
    return getLoginTokenUseCase(payload);
  });

  const resetErrorState = () => {
    setEmailInputErrorState('None');
    setSignInErrorState(SignInInitialState);
  };

  const signIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPasswordVisibility(false);
    resetErrorState();
    const { email, password } = signInState;
    const invalidFormErrors = { ...SignInInitialState };

    if (!formInvalid && isEmail(email)) {
      try {
        const res = await signInMutation.mutateAsync({ email: email, password: password });
        updateAccount({ ...res });
        history.push(returnTo || '/'); // Route to return_to if defined
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setEmailInputErrorState('Error');
          invalidFormErrors.password = t('sign_in_block.generic_error');
        }
      }
    } else {
      if (isEmptyString(password)) {
        invalidFormErrors.password = t('sign_in_block.missing_password');
      }
      if (isEmptyString(email)) {
        setEmailInputErrorState('Error');
        invalidFormErrors.email = t('sign_in_block.missing_email');
      } else if (!isEmail(email)) {
        setEmailInputErrorState('Error');
        invalidFormErrors.email = t('sign_in_block.incorrect_email');
      }
    }
    setSignInErrorState({
      ...signInErrorState,
      ...invalidFormErrors,
    });
  };

  return {
    ...props,
    logoWithHeader: {
      text: {
        value: t('sign_in_block.title'),
      },
    },
    emailField: {
      label: {
        value: t('sign_in_block.email'),
      },
      textInput: {
        dataTestId: 'email-field',
        textValue: signInState.email,
        onTextChanged: handleTextChange('email'),
        error: emailInputErrorState,
      },
      text: {
        dataTestId: 'email-error',
        value: signInErrorState.email,
      },
      error: signInErrorState.email ? 'Error' : 'None',
    },
    passwordField: {
      label: {
        value: t('sign_in_block.password'),
      },
      textInput: {
        inputType: passwordVisibility ? 'text' : 'password',
        button: {
          onClick: () => setPasswordVisibility(!passwordVisibility),
          icon: {
            asset: passwordVisibility ? 'HidePassword' : 'ShowPassword',
          },
          buttonType: 'button',
        },
        dataTestId: 'password-field',
        textValue: signInState.password,
        onTextChanged: handleTextChange('password'),
      },
      text: {
        dataTestId: 'password-error',
        value: signInErrorState.password,
      },
      error: signInErrorState.password ? 'Error' : 'None',
    },
    signInButton: {
      dataTestId: 'sign-in-button',
      text: {
        value: t('sign_in_block.sign_in_button'),
      },
      buttonType: 'submit',
    },
    forgotPasswordButton: {
      text: {
        value: t('sign_in_block.forgot_password_button'),
      },
      onClick: handleForgotPassword,
      buttonType: 'button',
    },
    signInForm: {
      onSubmit: signIn,
    },
  };
};

export default usePresenter;
