import { useQueryClient } from 'react-query';
import { Account, useAuth } from 'spindl-shared-components';

const useUpdateAccount = () => {
  const { updateAccount } = useAuth();
  const queryClient = useQueryClient();
  
  const updateAccountAndClearCache = (account?: Account) => {
    queryClient.clear();
    updateAccount(account);
  };

  return {
    updateAccount: updateAccountAndClearCache,
  };
};

export default useUpdateAccount;
