import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import englishTranslations from './translations/en.json';

// Creates a local i18n instance
const i18nInstance = i18n.createInstance();

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18nInstance
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: englishTranslations,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18nInstance;
