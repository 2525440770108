import React from 'react';
import cx from 'classnames';

import {
  Border,
  SpindlLogo,
} from 'spindl-shared-components';
import AccountMenuDropDown from '../../molecules/AccountMenuDropDown';
import AppDropDown from '../../molecules/AppDropDown';

import styles from './TopNav.module.scss';
import usePresenter from './TopNav.presenter';
import getProps from './TopNav.props';
import { TopNavCombinedProps } from './types';

const TopNav: React.FC<TopNavCombinedProps> = (props) => {
  const {
    className,
    classes,
    spindlLogo,
    border,
    appDropDown,
    accountMenuDropDown,
    border1,
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.topNav;

  return (
    <div className={cx(currentStyle, className)}>
      <div className={cx(styles.content, classes?.content)}>
        <div className={cx(styles.leftContent, classes?.leftContent)}>
          <div className={cx(styles.logoContainer, classes?.logoContainer)}>
            <SpindlLogo
              className={cx(styles.spindlLogo, classes?.spindlLogo)}
              {...internalProps.spindlLogo}
              {...spindlLogo}
            />
          </div>
          <Border
            className={cx(styles.border, classes?.border)}
            {...internalProps.border}
            {...border}
          />
          <AppDropDown
            className={cx(styles.appDropDown, classes?.appDropDown)}
            {...internalProps.appDropDown}
            {...appDropDown}
          />
        </div>
        <AccountMenuDropDown
          className={cx(styles.accountMenuDropDown, classes?.accountMenuDropDown)}
          {...internalProps.accountMenuDropDown}
          {...accountMenuDropDown}
        />
      </div>
      <Border
        className={cx(styles.border1, classes?.border1)}
        {...internalProps.border1}
        {...border1}
      />
    </div>
  );
};

export default TopNav;
