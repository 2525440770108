import React from 'react';
import cx from 'classnames';

import {
  Icon,
  Text,
} from 'spindl-shared-components';
import Initials from '../../atoms/Initials';

import styles from './AccountMenuDropDown.module.scss';
import usePresenter from './AccountMenuDropDown.presenter';
import getProps from './AccountMenuDropDown.props';
import { AccountMenuDropDownCombinedProps } from './types';

// MANUAL OVERRIDE - converted to forwardRef Component
const AccountMenuDropDown = React.forwardRef<HTMLButtonElement, AccountMenuDropDownCombinedProps>((props, ref) => {
  const {
    state,
    className,
    classes,
    disabled,
    buttonType,
    buttonForm,
    href,
    target,
    externalLink,
    onClick,
    initials,
    name,
    icon,
    ...otherProps // MANUAL OVERRIDE
  } = usePresenter(props);

  const variantName = `${state}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`accountMenuDropDown${variantName}`];

  let componentView;

  switch (variantName) {
    case 'Collapsed':
    case 'Expanded': {
      componentView = (
        <button
          ref={ref} // MANUAL OVERRIDE
          disabled={disabled}
          type={buttonType}
          form={buttonForm}
          onClick={onClick}
          className={cx(currentStyle, className)}
          {...otherProps} // MANUAL OVERRIDE
        >
          <Initials
            className={cx(styles.initials, classes?.initials)}
            {...internalProps.initials}
            {...initials}
          />
          <Text
            className={cx(styles.name, classes?.name)}
            {...internalProps.name}
            {...name}
          />
          <Icon
            className={cx(styles.icon, classes?.icon)}
            {...internalProps.icon}
            {...icon}
          />
        </button>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
}); // MANUAL OVERRIDE

export default AccountMenuDropDown;
