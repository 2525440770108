import React from 'react';
import cx from 'classnames';

import {
  Text,
  Icon,
  IconDropDown,
} from 'spindl-shared-components';
import Initials from '../../atoms/Initials';
import TitleWithDescription from '../../molecules/TitleWithDescription';

import styles from './TeamCard.module.scss';
import usePresenter from './TeamCard.presenter';
import getProps from './TeamCard.props';
import { TeamCardCombinedProps } from './types';

const TeamCard: React.FC<TeamCardCombinedProps> = (props) => {
  const {
    state,
    type,
    className,
    classes,
    initials,
    iconDropDown,
    titleWithDescription,
    role,
    icon,
    email,
    status,
  } = usePresenter(props);

  const variantName = `${state}${type}`;
  const internalProps = getProps(variantName);
  const currentStyle = styles[`teamCard${variantName}`];

  let componentView;

  switch (variantName) {
    case 'AddedButton': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.topContent, classes?.topContent)}>
              <div className={cx(styles.header, classes?.header)}>
                <div className={cx(styles.spacerContainer, classes?.spacerContainer)}/>
                <Initials
                  className={cx(styles.initials, classes?.initials)}
                  {...internalProps.initials}
                  {...initials}
                />
                <div className={cx(styles.buttonContainer, classes?.buttonContainer)}>
                  <IconDropDown
                    className={cx(styles.iconDropDown, classes?.iconDropDown)}
                    {...internalProps.iconDropDown}
                    {...iconDropDown}
                  />
                </div>
              </div>
              <TitleWithDescription
                className={cx(styles.titleWithDescription, classes?.titleWithDescription)}
                {...internalProps.titleWithDescription}
                {...titleWithDescription}
              />
            </div>
            <Text
              className={cx(styles.role, classes?.role)}
              {...internalProps.role}
              {...role}
            />
          </div>
        </div>
      );
      break;
    }
    case 'AddedNoButton': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.content, classes?.content)}>
            <div className={cx(styles.topContent, classes?.topContent)}>
              <div className={cx(styles.header, classes?.header)}>
                <Initials
                  className={cx(styles.initials, classes?.initials)}
                  {...internalProps.initials}
                  {...initials}
                />
              </div>
              <TitleWithDescription
                className={cx(styles.titleWithDescription, classes?.titleWithDescription)}
                {...internalProps.titleWithDescription}
                {...titleWithDescription}
              />
            </div>
            <Text
              className={cx(styles.role, classes?.role)}
              {...internalProps.role}
              {...role}
            />
          </div>
        </div>
      );
      break;
    }
    case 'PendingButton': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <div className={cx(styles.header, classes?.header)}>
              <div className={cx(styles.spacerContainer, classes?.spacerContainer)}/>
              <Icon
                className={cx(styles.icon, classes?.icon)}
                {...internalProps.icon}
                {...icon}
              />
              <div className={cx(styles.buttonContainer, classes?.buttonContainer)}>
                <IconDropDown
                  className={cx(styles.iconDropDown, classes?.iconDropDown)}
                  {...internalProps.iconDropDown}
                  {...iconDropDown}
                />
              </div>
            </div>
            <Text
              className={cx(styles.email, classes?.email)}
              {...internalProps.email}
              {...email}
            />
          </div>
          <Text
            className={cx(styles.status, classes?.status)}
            {...internalProps.status}
            {...status}
          />
        </div>
      );
      break;
    }
    case 'PendingNoButton': {
      componentView = (
        <div className={cx(currentStyle, className)}>
          <div className={cx(styles.topContent, classes?.topContent)}>
            <div className={cx(styles.header, classes?.header)}>
              <Icon
                className={cx(styles.icon, classes?.icon)}
                {...internalProps.icon}
                {...icon}
              />
            </div>
            <Text
              className={cx(styles.email, classes?.email)}
              {...internalProps.email}
              {...email}
            />
          </div>
          <Text
            className={cx(styles.status, classes?.status)}
            {...internalProps.status}
            {...status}
          />
        </div>
      );
      break;
    }
    default:
      componentView = <div>�</div>;
      break;
  }

  return componentView;
};

export default TeamCard;
