import React from 'react';
import cx from 'classnames';
import { Route, Switch } from 'react-router-dom';

import { TemplateEditorModule } from 'spindl-communication-mod';
import { DatabaseEditorModule } from 'spindl-data-mod';
import { PreviewModule } from 'spindl-studio-mod';
import TopNav from '../../organisms/TopNav';

import { EDITOR_ROUTES } from '../../../lib/constants';
import styles from './EditorLayout.module.scss';
import usePresenter from './EditorLayout.presenter';
import getProps from './EditorLayout.props';
import { EditorLayoutCombinedProps } from './types';

const EditorLayout: React.FC<EditorLayoutCombinedProps> = (props) => {
  const {
    className,
    classes,
    topNav,
    hideTopNav, // MANUAL OVERRIDE
  } = usePresenter(props);

  const internalProps = getProps('');
  const currentStyle = styles.editorLayout;

  // MANUAL OVERRIDE
  const topNavView = hideTopNav ? null : (
    <TopNav
      className={cx(styles.topNav, classes?.topNav)}
      {...internalProps.topNav}
      {...topNav}
    />
  );

  return (
    <div className={cx(currentStyle, className)}>
      {/* MANUAL OVERRIDE */}
      {topNavView}

      {/* MANUAL OVERRIDE */}
      <div className={cx(styles.content, classes?.content)}>
        {/* Services in editor-mode */}
        <Switch>
          <Route path={EDITOR_ROUTES.communication} component={TemplateEditorModule} />
          <Route path={EDITOR_ROUTES.data} component={DatabaseEditorModule} />
          <Route path={EDITOR_ROUTES.preview} component={PreviewModule} />
        </Switch>
      </div>
    </div>
  );
};

export default EditorLayout;
