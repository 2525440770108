import decode from 'jwt-decode';
import { Account } from '../modules/auth/types';

export function extractJwtPayload(jwt: string): Account & {
  exp: number;
} {
  const payload = window.atob(jwt.split('.')[1]);
  return JSON.parse(payload);
}

export const decodeInviteToken = (token: string): InviteTokenPayload | null => {
  try {
    return decode<InviteTokenPayload>(token);
  } catch {
    return null;
  }
};

export type InviteTokenPayload = {
  appId: number;
  email: string;
  exp: number;
};
