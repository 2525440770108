import { TopNavProps } from '../../organisms/TopNav';


type EditorLayoutInternalProps = {
  topNav?: TopNavProps;
};

type EditorLayoutPropsValues = {
  '': EditorLayoutInternalProps;
};

const propValues: EditorLayoutPropsValues = {
  '': {
    topNav: {
    },
  },
};

const getProps = (type: string): EditorLayoutInternalProps => {
  const values: EditorLayoutInternalProps = propValues[type];
  if (!values) {
    console.error(`${type} props not found`);
    return {};
  }
  return values;
};

export default getProps;
