import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { NotificationContainer } from 'spindl-shared-components';
import { StudioModuleProvider } from 'spindl-studio-mod';

import styles from './App.module.scss';
import { BASE_SERVICE_ROUTES, EDITOR_ROUTES } from './lib/constants';
import EditorLayout from './components/layouts/EditorLayout';
import MainLayout from './components/layouts/MainLayout';
import SignInLayout from './components/layouts/SignInLayout';
import ErrorPage from './components/pages/ErrorPage';
import { AppsContext } from './modules/apps/AppsContext';
import DefaultAppRoute from './modules/apps/DefaultAppRoute';
import HandleApps from './modules/apps/HandleApps';
import ProtectedRoute from './modules/auth/ProtectedRoute';

const App: React.FC<{}> = () => {
  const { activeApp } = useContext(AppsContext);
  const appPages = Object.keys(BASE_SERVICE_ROUTES).join('|');
  const editorRoutes = Object.values(EDITOR_ROUTES);

  return (
    <Router>
      {/* MANUAL OVERRIDE */}
      {/* Notification container used across all modules */}
      <NotificationContainer className={styles.notificationContainer} />

      <Switch>
        {/* Auth routes */}
        <Route path='/auth/'>
          <SignInLayout className={styles.layout} />
        </Route>

        {/* Editor-mode routes (communication and data services) */}
        <ProtectedRoute path={[...editorRoutes]}>
          <HandleApps>
            <StudioModuleProvider spindlApp={activeApp}>
              <EditorLayout className={styles.layout} />
            </StudioModuleProvider>
          </HandleApps>
        </ProtectedRoute>

        {/* Base app route redirects to the users service */}
        <Redirect exact push from='/apps/:appId' to={BASE_SERVICE_ROUTES.design} />

        {/* Main service routes */}
        <ProtectedRoute path={`/apps/:appId/:activePage(${appPages})`}>
          <HandleApps>
            <StudioModuleProvider spindlApp={activeApp}>
              <MainLayout className={styles.layout} />
            </StudioModuleProvider>
          </HandleApps>
        </ProtectedRoute>

        {/* Base route handler */}
        <DefaultAppRoute exact path='/' />

        {/* No match handler (404 page) */}
        <Route path='*'>
          <ErrorPage className={styles.layout} />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
